<template>
  <div>
    <!-- 后台用户 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.name" clearable placeholder="请输入用户姓名" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
      <el-form-item class="fr">
        <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false),handleShop()" 
        v-if="OA.includes('systems:users:add')">添加</el-button>
      </el-form-item>
    </el-form>
    
    <div class="content-wrap">
      <!--列表-->
      <paged-table
        :data="userList"
        :total="userTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        v-model="queryParams"
        @refresh="refresh"
      > 
        <el-table-column sortable="custom" prop="userName" show-overflow-tooltip label="用户账号" width="250"></el-table-column>
        <el-table-column sortable="custom" prop="realName" show-overflow-tooltip label="用户姓名"></el-table-column>
        <el-table-column sortable="custom" prop="mobile" show-overflow-tooltip label="手机号"></el-table-column>
        <el-table-column sortable="custom" show-overflow-tooltip label="所在区域">
          <template slot-scope="scope">
            <span>{{ scope.row.extend.userAreaName ? scope.row.extend.userAreaName : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" show-overflow-tooltip label="状态" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'">{{ scope.row.status == 1 ? '启用' : '停用' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="userRoleNames" show-overflow-tooltip label="所属角色"></el-table-column>
        <el-table-column sortable="custom" prop="remark" show-overflow-tooltip label="备注"></el-table-column>
        <el-table-column align="right" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button type="success" size="mini" title="分配菜单权限" @click="handleRole(scope.row)"><img class="loginHead" src="@/assets/images/powers.svg" alt=""></el-button>
            <el-button size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button size="mini" type="primary" @click="handleForm(true, scope.row),handleShop()" icon="el-icon-edit" title="编辑" v-if="OA.includes('systems:users:editor')" ></el-button>
            <el-button size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除" v-if="OA.includes('systems:users:del')" ></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑用户' : '添加用户'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="用户账号：" prop="userName">
          <el-input v-model="form.userName" clearable :disabled="isEditor"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password" v-if="!isEditor">
          <el-input v-model="form.password" show-password clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model="form.mobile" clearable :maxlength="11" :disabled="isEditor"></el-input>
        </el-form-item>
        <el-form-item label="用户姓名：" prop="realName">
          <el-input v-model="form.realName" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item label="所在区：" prop="userArea">
          <el-select clearable filterable v-model="form.userArea">
            <el-option v-for="item in areaOptions" :label="item.name" :value="item.id.toString()" :key="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="所属角色：" prop="userRoleIds">
          <el-select clearable filterable v-model="userRoleIds" multiple @change="form.userRoleIds = userRoleIds.toString()">
            <el-option v-for="item in roleOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择店铺：" prop="userRoleIds">
          <el-select clearable filterable v-model="form.shopId">
            <el-option v-for="item in shopOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用：" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="店铺管理：" prop="isAdmin">
          <el-radio-group v-model="form.isAdmin">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="头像上传：">
          <base-upload v-model="form.headImg" fileType="single" @beforeUpload="handleBeforeUpload"></base-upload>
          <div class="tips">图片格式只支持：JPG、PNG</div>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" v-model="form.remark" clearable :row="3"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitFormB(editorApi) : submitFormB(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="用户详情" :visible.sync="bVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="用户账号：" prop="userName">
          <span v-text="details.userName"></span>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <span v-text="details.mobile"></span>
        </el-form-item>
        <el-form-item label="用户姓名：" prop="realName">
          <span v-text="details.realName"></span>
        </el-form-item>
        <el-form-item label="所在区：" prop="userAreaName">
          <span v-text="details.extend.userAreaName"></span>
        </el-form-item>
        <el-form-item label="头像上传：" prop="headImg">
          <img :src="details.headImg" width="200">
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <span v-text="details.remark"></span>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="权限分配" :visible.sync="roleVisible" :before-close="resetRole" width="500px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="formRoleFileds" :model="formRole" :rules="formRoleRules" size="small">
        <el-form-item label="选择学校：" prop="schoolId">
          <el-select clearable filterable v-model="formRole.schoolId" @change="distShow">
            <el-option v-for="item in schoolOptions" :label="item.schoolName" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择校区：" prop="distId">
          <el-select clearable filterable v-model="formRole.distId">
            <el-option v-for="item in distOptions" :label="item.distName" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择运营商：" prop="operator">
          <el-select clearable filterable v-model="formRole.operator">
            <el-option label="全部" :value="null"></el-option>
            <el-option label="中国电信" value="中国电信"></el-option>
            <el-option label="中国移动" value="中国移动"></el-option>
            <el-option label="中国联通" value="中国联通"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetRole()">取消</el-button>
        <el-button size="small" type="primary" @click="submitRole">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseUpload from "@/components/common/BaseUpload";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'userManagement',
  components: {
    PagedTable,
    BaseUpload
  },
  mixins: [pageMixin, provinces],
  data() {
    return {
      queryParams: {
        shopId: 1,
      },
      roleVisible: false,
      formRole: {
        id: null,
        schoolId: null,
        distId: null,
        operator: '',
      },
      formRoleRules: {
        schoolId: [{ required: true, message: '请选择学校', trigger: 'blur' }],
        distId: [{ required: true, message: '请选择校区', trigger: 'blur' }],
        operator: [{ required: true, message: '请选择运营商', trigger: 'blur' }],
      },
      userRoleIds:[],
      roleOptions:[],

      form:{
        id:'',
        shopId: null,
        userName:'',
        realName:'',
        remark:'',
        password:'',
        headImg:'',
        mobile:'',
        status:'1',
        userArea:'',
        userRoleIds:'',
        isAdmin: "1",
      },
      schoolOptions: [],
      distOptions: [],
      shopOptions: [],
      details:{
        userName:'',
        realName:'',
        remark:'',
        password:'',
        headImg:'',
        mobile:'',
        status:'',
        userArea:'',
        extend:{}
      },
      formRules:{
        userName: [{ required: true, message: '请输入用户账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入用户密码', trigger: 'blur' }],
        realName: [{ required: true, message: '请输入用户姓名', trigger: 'blur' }],
      },

      pageApi:'membersSysList',
      insertApi:'membersSysAdd',
      editorApi:'membersSysEdit',
      deleteApi:'membersSysDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("systemUser", ["userList", "userTotal"]),
  },
  methods: {
    ...mapActions("systemRole",["commonRoleAll"]),
    ...mapActions("systemUser",["membersSysList", "membersSysAdd", "membersSysEdit", "membersSysDelete", "shopGetSchoolByScopeType", "membersSchoolSelectAll", "shopGetShopSelect"]),
    handleBeforeUpload(file, callback){
		  const isPicture = file.name.includes('jpg') || file.name.includes('png')
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isPicture) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      callback(isPicture && isLt2M)
    },
    handleForm(isEditor = false, row, afterFun = null) {
      this.aVisible = true;
      this.isEditor = isEditor
      this.userRoleIds = []
      this.$nextTick(async () => {
        this.$refs.formFileds.resetFields();
        if (isEditor) {
          mergeObject(this.form, row)
          this.userRoleIds = row.userRoleIds ? row.userRoleIds.split(',').map(item=>parseInt(item)) : ''
          log(this.userRoleIds)
        }
      })
    },
    async submitFormB(api) {
      let params = Object.assign({}, this.form)
      this.form.shopId = sessionStorage.getItem('shopId')
      this.$refs.formFileds.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交保存吗？', '提示', {}).then(async () => {
            this.bLoading = true
            try {
              if(this.isEditor){
                delete params.userName
                delete params.mobile
                delete params.password
              }else{
                delete params.id
              }
              await this[api](params)
              this.$message.success('数据保存成功！')
              this.aVisible = false
              this.refresh()
            }catch(e){
              log(e)
            }finally {
              this.bLoading = false
            }
          })
        }
      })
    },
    async handleRole(row) {
      console.log(row)
      this.roleVisible = true;
      this.formRole.id = row.id
      try{
        let resA = await this.shopGetSchoolByScopeType({shopId: sessionStorage.getItem('shopId')-0})
        this.schoolOptions = resA
      }catch(e){
        log(e)
      }
    },
    async handleShop() {
      try{
        let resB = await this.shopGetShopSelect()
        this.shopOptions = resB
      }catch(e){
        log(e)
      }
    },
    async distShow() {
      try{
        let resB = await this.membersSchoolSelectAll({schoolId: this.formRole.schoolId})
        this.distOptions = resB
      }catch(e){
        log(e)
      }
    },
    submitRole() {
      this.$refs.formRoleFileds.validate((valid) => {
        if (valid) {
          try{
            this.$delete(this.queryParams,'appId')
            this.membersSysEdit({id: this.formRole.id})
          }catch(error){}finally{ 
            this.resetRole()
          }
        }
      })
    },
    resetRole(){
      this.roleVisible = false
      this.$refs.formRoleFileds.resetFields()
    },
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()

    this.cityChange(101001340010, this.form.area)
    const saas = sessionStorage.getItem('shopId')
    this.roleOptions = await this.commonRoleAll({saas})
    this.queryParams.shopId = sessionStorage.getItem('shopId')
  }
};
</script>